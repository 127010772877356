<template>
    <div class="init">
        <leftView ref='leftViewRef' />
        <topView>
            <div class="main_">
                <tagsView />
                <div class="init_main">
                    <!-- <keep-alive>
                    <router-view v-slot="{ Component }">
                        <transition name="page-slide" mode="out-in">
                            <component :is="Component" />
                        </transition>
                    </router-view>
                    </keep-alive> -->
                    <router-view v-slot="{ Component }">
                        <keep-alive>
                            <component :is="Component" />
                        </keep-alive>
                    </router-view>
                </div>
            </div>
        </topView>
    </div>
</template>
<script setup>
import { ref } from 'vue'
import leftView from './lefts.vue'
import topView from './top.vue'
import tagsView from './tags.vue'
import { provide } from 'vue';

const leftViewRef = ref(null);
const toSwitch = () => {
    if (leftViewRef.value) {
        leftViewRef.value.changeMenu()
    }
}
provide('toSwitch', toSwitch);
</script>

<style lang="scss" scoped>
@import "@/assets/scss/parts.scss"; //变量样式

.page-slide-enter-active,
.page-slide-leave-active {
    transition: transform .5s cubic-bezier(0.68, -0.55, 0.27, 1.55); 
}

.page-slide-enter,
.page-slide-leave-to {
    // transform: translateX(110%);
    opacity: 0.2;
}

.init {
    @include display_;

    .main_ {
        background: #F0F0F0;
        height: calc(100vh - 60px);

        .init_main {
            padding: 20px;
            margin: 8px 10px;
            background: #fff;
            border-radius: 5px;
        }

    }
}</style>