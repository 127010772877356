<template>
    <div class="tags">
        <div class="tags_next" v-if='isLeft'>
            <el-icon @click="lastView"><ArrowLeft /></el-icon>
        </div>
        <div class="tags_san"  ref="single_">
            <div class="tags_san1">
                <div :class="isPath(item)?'tags_single tags_single_active':'tags_single'" v-for='(item,index) in tagsArray.getTagsArray' 
                    :key='item.id' @click="jumpUrl(item)" ref="single_1">
                    {{getIsHome(item)}}
                    <el-icon class="close_" @click.stop="closeTags(index)" v-if='tagsArray.getTagsArray.length > 1'><CloseBold /></el-icon> 
                </div>
            </div>
        </div>
        <div class="tags_next" v-if='isRight'>
            <el-icon @click="nextView"><ArrowRight /></el-icon>
        </div>
        <div class="tags_button" v-if="tagsArray.getTagsArray.length > 1">
            <span class="tags_button_main" @click="closeAll">
                <span >{{$t('button.closeq1')}}</span>
            </span>
        </div>
    </div>
</template>
<script setup>
    import { ref,getCurrentInstance,onMounted } from 'vue'
    import { CloseBold,ArrowLeft,ArrowRight } from '@element-plus/icons-vue'
    import { configStore } from '@/store/config.js'
    import { useRouter } from 'vue-router'
    const { $t } = getCurrentInstance().proxy;
    // import BScroll from 'better-scroll'

    const tagsArray = ref(configStore())
    const router = useRouter()
    const single_ = ref(null)
    const single_1 = ref(null)
    const _index = ref(0)//当前的导航在第几个

    const isLeft = ref(false)
    const isRight = ref(false)
 
    onMounted(()=>{
        position_()
    })
    const position_ = ()=>{//首次刷新的时候判断当前路由的位置，如果在滚动条外面不可见，手动移动到最左边
        for(let index = 0; index < tagsArray.value.getTagsArray.length;++ index){
            if(tagsArray.value.getTagsArray[index].id === router.currentRoute.value.meta.id){
                _index.value = index
                break;
            }
        }
        if(tagsArray.value.getTagsArray.length === 0){
            console.log(router.currentRoute.value)
            configStore().setTagsArray([{...router.currentRoute.value.meta,path: '/main/homePage'}])
        }
        //第一次进来的时候是首页的时候，记录当前

        //如果当前的没有滚动条就不考虑；有滚动条，当前标签无法移动弄到最左端；有，可以移动到最左端，还有剩余；
        commonSizeFun((far_,son_)=>{
            let _allWidth = 0
            for(let i = 0;i < _index.value;++ i){
                _allWidth = _allWidth + single_1.value[i].clientWidth
            }
            if(son_ - _allWidth > far_){
                jumpWidth(_allWidth)
                judgment(true,true)
            }else{
                jumpWidth(son_ - far_)
                judgment(true,false)
            }
        })
    }
    const lastView = ()=>{
        commonSizeFun(()=>{
            let _allWidth = 0
            for(let i = 0;i < single_1.value.length;++ i){
                _allWidth = _allWidth + single_1.value[i].clientWidth
                if(_allWidth > single_.value.scrollLeft){
                    let bool_ = i === single_1.value.length - 2?false:true
                    jumpWidth(_allWidth - single_1.value[i].clientWidth)
                    judgment(bool_,true)
                    break;
                }else if(_allWidth == single_.value.scrollLeft){
                    let bool_ = i === single_1.value.length - 2?false:true
                    judgment(bool_,true)
                    jumpWidth(single_.value.scrollLeft - single_1.value[i].clientWidth)
                    break;
                }
            }
        })
    }
    const nextView = ()=>{
        commonSizeFun((far_)=>{
            let _allWidth = 0
            for(let i = 0;i < single_1.value.length;++ i){
                _allWidth = _allWidth + single_1.value[i].clientWidth
                if(_allWidth > single_.value.scrollLeft + far_ || _allWidth == single_.value.scrollLeft + far_){
                    let bool_ = i === single_1.value.length - 2?false:true
                    judgment(true,bool_)
                    let index_ = i === single_1.value.length - 1?single_1.value.length - 1:i + 1
                    jumpWidth(single_.value.scrollLeft + single_1.value[ index_ ].clientWidth)
                    break;
                }
            }
        })
    }
    const closeTags = (index)=>{//
        let tagsArray_ = configStore().getTagsArray
        if(index === 0){    
            router.push({ path: tagsArray_[1].path })
            configStore().setCheckRouteId(tagsArray_[1].id)
        }else{
            router.push({ path: tagsArray_[index - 1].path })
            configStore().setCheckRouteId(tagsArray_[index - 1].id)
        }
        tagsArray_.splice(index,1);
        configStore().setTagsArray(tagsArray_)
        _judgment()
    }
    const jumpUrl = (item)=>{
        configStore().setCheckRouteId(item.id.toString())
        router.push({ path: item.path })
    }
    const isPath = (item) => {
        if(item.id == router.currentRoute.value.meta.id){
            return true
        }else{
            return false
        }
    }
    const closeAll = ()=>{
        for(let item of tagsArray.value.getTagsArray){
            if(item.id === tagsArray.value.getCheckRouteId ){
                configStore().setTagsArray([item])
                return 0
            }
        }
        judgment(false,false)
    }
    const getIsHome = (item)=>{//是否是首页，判断国际化
        if(item.id === '-2'){
            return $t('pages.homeName')
        }else{
            return item.name
        }
    }
    const jumpWidth = (width)=>{//跳转到某个界面
        single_.value.scrollTo({
            top: 0,
            left: width,
            behavior: 'smooth'
        })
    }
    const commonSizeFun = (balk)=>{
        let far_ = single_.value.clientWidth;
        let son_ = single_.value.children[0].clientWidth;
        if(son_ > far_){//有滚动条
            balk(far_,son_)
        }else{
            judgment(false,false)
        }
    }
    const _judgment = ()=>{
        let far_ = single_.value.clientWidth;
        let son_ = single_.value.children[0].clientWidth;
        let body_ = single_.value.scrollLeft
        if(son_ > far_){//有滚动条
            if(son_ > body_ + far_){
                judgment(true,true)
            }else if(son_ === body_ + far_ ){
                judgment(true,false)
            }else if(body_ === 0){
                judgment(false,true)
            }
        }else{
            judgment(false,false)
        }

    }
    const judgment = (bool1,bool2)=>{//判断左右箭头的显示
        isLeft.value = bool1
        isRight.value = bool2
    }
</script>
<style lang="scss" scoped>
    .tags{
        height: 40px;
        background: #fff;
        display: flex;
        position: relative;
        &_next{
            display: flex;align-items: center;justify-content: center;
            width: 50px;height: 100%;font-size: bold;
            cursor: pointer;
        }
        &_san{
            height: 42px;
            width: calc(100% - 240px);
            margin-left: 10px;
            display: flex;align-items: flex-start;
            padding-bottom: 20px;
            overflow-x: hidden;
            overflow-y: auto;

        }
        &_san1{
            height: 100%;
            display: flex;align-items: center;
        }
        &_button{
            position: absolute;
            right: 15px;
            top: 0;
            background: rgb(64,158,255);
            border-radius: 5px;
            padding:0 10px;
            margin: 5px;
            cursor: pointer;
            &_main{
                color: #fff;height: 30px;
                display: flex;align-items: center;
            }
        }
        &_single{
            height: 100%;
            display: flex;align-items: center;
            padding: 0 25px;
            color: #ccc;
            cursor: pointer;
            white-space:nowrap;
            // &:first-child{
            //     padding-left: 0;
            // }

            &:hover{
                color: #000;
                .close_{
                    display: block;
                }
            }
            .close_{
                margin-left: 10px;
                display: none;
                &:hover{
                    transform: scale(1.2);
                    // opacity: 1;
                }
            }
        }
        .tags_single_active{
            border-bottom: 2px solid rgba(64,158,255,.5);
            color: #000;
            height: 38px;
            .close_{
                display: block;
            }
        }
    }
</style>

